.whPOProductQtyModalInput input[type=text] {
  font-size: 4em;
  width: 14rem;
  text-align: center !important;
  height: 7rem;
  padding: 1rem !important;
}

.whPOProductQtyModalGridRows {
  padding: 1rem 0;
}