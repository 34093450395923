.shipmentsAccordion{
  margin-bottom: 1rem;
}

.shipmentsAccordion .title {
  color: rgba(0, 0, 0, 0.5);
}

.shipmentsAccordion .ui.grid {
  max-width: 100%;
}

.shipmentsAccordion .listing-grid .react-grid-Grid {
  height: auto !important;
  border-top: solid #E3E3E3 1px;
}

.shipmentsAccordion .react-grid-Canvas {
  overflow: hidden !important;
}

.shipmentsAccordion .content.active.listing-grid {
  display: contents !important;
}
